import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/page-legal.module.scss"
import Navbar from "./navbar"
import Layout from "./layout"

export default function LegalPage(props) {
  const {
    pageContext: { html },
  } = props
  return (
    <Layout>
      <Navbar />
      <div
        className={styles.root}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Layout>
  )
}

LegalPage.propTypes = {
  pageContext: PropTypes.shape({
    html: PropTypes.string,
  }),
}
